import { defineStore } from "pinia";

export const useIndexedDBCacheStore = defineStore("cache-indexeddb", () => {
  let available = false;
  const dbName = "cacheDB";
  const storeName = "cacheStore";

  function init() {
    testIfIndexedDBAvailable();
  }

  function openDB() {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open(dbName, 2);
      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        db.createObjectStore(storeName, { keyPath: "key" });
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async function set(key: string, value: any, ttl: number, fromDate?: number) {
    await waitUntilTrue(() => available);
    const now = fromDate || Date.now();
    const expires = now + ttl * 1000;
    const db = await openDB();
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    store.put({ key, value, expires });
  }

  // function that clears expired cache
  async function clearExpired() {
    await waitUntilTrue(() => available);
    const db = await openDB();
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.openCursor();
    request.onsuccess = () => {
      const cursor = request.result;
      if (cursor && cursor.value && cursor.value.expires) {
        if (cursor.value.expires < Date.now()) {
          store.delete(cursor.key);
        }
        cursor.continue();
      }
    };
  }

  async function get(key: string) {
    await clearExpired();
    await waitUntilTrue(() => available);
    const db = await openDB();
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    return new Promise((resolve, reject) => {
      const request = store.get(key);
      request.onsuccess = () => {
        const result = request.result;
        if (result && result.expires > Date.now()) {
          resolve(result.value);
        } else {
          store.delete(key);
          resolve(null);
        }
      };
      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  function testIfIndexedDBAvailable() {
    try {
      const request = indexedDB.open(dbName);
      request.onsuccess = () => {
        available = true;
      };
      request.onerror = () => {
        available = false;
      };
    } catch (e) {
      available = false;
    }
  }

  const OneDay = 60 * 60 * 24;
  const OneHour = 60 * 60;

  init();

  return { set, get, OneDay, OneHour };
});
