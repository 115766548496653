<script setup lang="ts">
const store = useMobileStore();
</script>

<template>
  <button
    :aria-label="$t('SV.LIT_ToggleNavigationMenu')"
    type="button"
    @click="store.toggleMobileNav()"
  >
    <FAIcon class="fa-light fa-bars fa-fw" />
  </button>
</template>

<style scoped lang="postcss">
button {
  @apply lg:hidden p-4 text-2xl -ml-2;
}
</style>
