import { LoadStatus } from "@/models/frontend-only/LoadStatus";
import { HomeData } from "@/models/HomeData";

const decodeHtmlCharCodes = (str: string) =>
  str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
    String.fromCharCode(charCode),
  );

export const useHomeStore = defineStore("home", () => {
  const busy = useBusyStore();
  const api = useApiStore();
  const status = ref<LoadStatus>(LoadStatus.Loading);
  const data = ref<HomeData | null>(null);

  async function getHomeData() {
    data.value = null;
    status.value = LoadStatus.Loading;
    busy.showBusy();
    try {
      data.value = await api.get("/GetHomeData");
      if (!data.value) {
        throw new Error("Home data missing");
      }
      if (isBeyondChristmasSeason()) {
        data.value.popularThemes = data.value.popularThemes.filter(
          (b) => !b.searchQuery.toLowerCase().includes("theme_christmas"),
        );
      }
      data.value.banners.forEach((b) => {
        b.id = uniqueId("banner");
        b.text.title = decodeHtmlCharCodes(b.text.title);
        b.text.subtitle = decodeHtmlCharCodes(b.text.subtitle);
      });
      status.value = LoadStatus.Ready;
    } catch (err) {
      status.value = LoadStatus.Error;
    }
    busy.hideBusy();
  }

  return {
    getHomeData,
    status,
    data,
  };
});
