import { SongSearchResult } from "@/models/SongSearchResult";
import { SharedList } from "@/models/SharedList";

export const useFavStore = defineStore("fav", () => {
  const api = useApiStore();
  const profile = useProfileStore();
  const favSongNumbers = ref<string[] | null>(null);
  const beta = useBetaStore();
  const enableSharedFavorites = computed(() => beta.features.sharedFavorites);

  const songs = ref<SongSearchResult[]>([]);
  const selectedSongNumber = ref("");
  const modals = useModalStore();

  const sharedLists = ref<SharedList[]>([
    {
      id: uniqueId("folder"),
      name: "Name of List",
      count: 10,
      images: [
        {
          src: "https://is5-ssl.mzstatic.com/image/thumb/Music115/v4/d7/7d/63/d77d63d8-44f7-9f75-b4c7-19cc20239120/886449027057.jpg/170x170bb.jpg",
          alt: "Album Artwork",
        },
        {
          src: "https://is2-ssl.mzstatic.com/image/thumb/Music116/v4/f5/d0/bc/f5d0bc20-3641-c73a-6278-1247f9ba159f/23SYMIM04054.rgb.jpg/170x170bb.jpg",
          alt: "Album Artwork",
        },
        {
          src: "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/13/5b/90/135b90b3-191d-3f69-3a8d-a10f42d665a3/080688997168.png/170x170bb.jpg",
          alt: "Album Artwork",
        },
        {
          src: "https://is3-ssl.mzstatic.com/image/thumb/Music122/v4/9a/fb/a9/9afba90e-e219-c1b1-f99c-e6b152026aef/22UMGIM71001.rgb.jpg/170x170bb.jpg",
          alt: "Album Artwork",
        },
      ],
    },
    {
      id: uniqueId("folder"),
      name: "Name of List 2",
      count: 3,
      images: [
        {
          src: "https://is5-ssl.mzstatic.com/image/thumb/Music115/v4/d7/7d/63/d77d63d8-44f7-9f75-b4c7-19cc20239120/886449027057.jpg/170x170bb.jpg",
          alt: "",
        },
        {
          src: "https://is2-ssl.mzstatic.com/image/thumb/Music116/v4/f5/d0/bc/f5d0bc20-3641-c73a-6278-1247f9ba159f/23SYMIM04054.rgb.jpg/170x170bb.jpg",
          alt: "",
        },
        {
          src: "https://is4-ssl.mzstatic.com/image/thumb/Music124/v4/13/5b/90/135b90b3-191d-3f69-3a8d-a10f42d665a3/080688997168.png/170x170bb.jpg",
          alt: "",
        },
      ],
    },
    {
      id: uniqueId("folder"),
      name: "Name of List 3",
      count: 2,
      images: [
        {
          src: "https://is5-ssl.mzstatic.com/image/thumb/Music115/v4/d7/7d/63/d77d63d8-44f7-9f75-b4c7-19cc20239120/886449027057.jpg/170x170bb.jpg",
          alt: "",
        },
        {
          src: "https://is2-ssl.mzstatic.com/image/thumb/Music116/v4/f5/d0/bc/f5d0bc20-3641-c73a-6278-1247f9ba159f/23SYMIM04054.rgb.jpg/170x170bb.jpg",
          alt: "",
        },
      ],
    },
    {
      id: uniqueId("folder"),
      name: "Name of List 4",
      count: 2,
      images: [
        {
          src: "https://is5-ssl.mzstatic.com/image/thumb/Music115/v4/d7/7d/63/d77d63d8-44f7-9f75-b4c7-19cc20239120/886449027057.jpg/170x170bb.jpg",
          alt: "",
        },
        {
          src: "https://is2-ssl.mzstatic.com/image/thumb/Music116/v4/f5/d0/bc/f5d0bc20-3641-c73a-6278-1247f9ba159f/23SYMIM04054.rgb.jpg/170x170bb.jpg",
          alt: "",
        },
      ],
    },
    {
      id: uniqueId("folder"),
      name: "Name of List 5",
      count: 2,
      images: [
        {
          src: "https://is5-ssl.mzstatic.com/image/thumb/Music115/v4/d7/7d/63/d77d63d8-44f7-9f75-b4c7-19cc20239120/886449027057.jpg/170x170bb.jpg",
          alt: "",
        },
        {
          src: "https://is2-ssl.mzstatic.com/image/thumb/Music116/v4/f5/d0/bc/f5d0bc20-3641-c73a-6278-1247f9ba159f/23SYMIM04054.rgb.jpg/170x170bb.jpg",
          alt: "",
        },
      ],
    },
    {
      id: uniqueId("folder"),
      name: "Name of List 6",
      count: 1,
      images: [
        {
          src: "https://is5-ssl.mzstatic.com/image/thumb/Music115/v4/d7/7d/63/d77d63d8-44f7-9f75-b4c7-19cc20239120/886449027057.jpg/170x170bb.jpg",
          alt: "",
        },
      ],
    },
    {
      id: uniqueId("folder"),
      name: "Name of List 7",
      count: 0,
      images: [],
    },
  ]);

  async function getSharedLists() {
    if (!profile.profile) {
      sharedLists.value = [];
      return;
    }
    sharedLists.value.splice;
    const response = (await api.get("/GetSharedLists", {
      orgId: profile.profile?.orgId,
    })) as SharedList[];
    sharedLists.value.splice(0);
    sharedLists.value.push(...response);
  }

  async function getListDetails(id: string) {
    const response = (await api.get("/GetSharedList", {
      id,
    })) as SharedList;
    return response;
  }

  async function createList(name: string) {
    const id = await api.post("/CreateList", {
      orgId: profile.profile?.orgId,
      name: name,
    });
    return id;
  }
  async function deleteList(id: string) {
    await api.post("/DeleteList", {
      id: id,
    });
    await getSharedLists();
  }
  async function renameList(id: string, name: string) {
    await api.post("/RenameList", {
      id: id,
      name: name,
    });
    await getSharedLists();
  }
  async function reorderList(id: string, songNumbers: string[]) {
    await api.post("/ReorderList", {
      id: id,
      songNumbers: songNumbers,
    });
    await getSharedLists();
  }
  async function addSongtoList(id: string, songNumber: string) {
    await api.post("AddSongToList", {
      id: id,
      songNumber,
    });
  }
  async function removeSongFromList(id: string, songNumber: string) {
    await api.post("AddSongToList", {
      id: id,
      songNumber,
    });
  }

  async function getFavorites() {
    const s: SongSearchResult[] = await api.get("/GetFavorites");
    songs.value = sortBy(s, (item) => item.title);
    favSongNumbers.value = songs.value.map((s) => s.songNumber);
  }

  async function getFavoriteSongNumbers() {
    waitUntilTrue(() => profile.profile);
    if (profile.profile?.signedIn) {
      favSongNumbers.value = await api.get("/GetFavoriteSongNumbers");
    }
  }

  async function addToFavorites(songNumber: string) {
    await api.post("/AddToFavorites", { songNumber });
    await getFavoriteSongNumbers();
  }

  async function removeFromFavorites(songNumber: string) {
    await api.post("/RemoveFromFavorites", { songNumber });
    await getFavoriteSongNumbers();
  }

  function addSongToSharedList(songNumber: string) {
    selectedSongNumber.value = songNumber;
    modals.showModal("SelectListModal");
  }

  function openNewListModal() {
    selectedSongNumber.value = "";
    modals.showModal("NewListModal");
  }

  getFavoriteSongNumbers();

  return {
    songs,
    sharedLists,
    favSongNumbers,
    getFavorites,
    addToFavorites,
    removeFromFavorites,
    enableSharedFavorites,
    getSharedLists,
    createList,
    deleteList,
    renameList,
    reorderList,
    getListDetails,
    addSongtoList,
    removeSongFromList,
    selectedSongNumber,
    addSongToSharedList,
    openNewListModal,
  };
});
