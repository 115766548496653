<script setup lang="ts">
const store = useMobileStore();
const active = computed(() => store.showMobileNav);
const enableDom = ref(false);
let timeout: number | null = null;
watch(active, () => {
  if (active.value) {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    enableDom.value = true;
  } else {
    timeout = window.setTimeout(() => {
      enableDom.value = false;
    }, 500);
  }
});
</script>
<template>
  <div
    v-if="enableDom"
    class="darken-overlay"
    :class="{ active: active }"
    @click="store.toggleMobileNav()"
  />
</template>

<style scoped lang="postcss">
.darken-overlay {
  @apply pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-40 bg-black bg-opacity-80 opacity-0 backdrop-blur-0 transition-all;
  &.active {
    @apply pointer-events-auto opacity-100 backdrop-blur-sm;
  }
  @media screen(lg) {
    @apply hidden;
  }
}
</style>
