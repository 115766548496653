export const AppConstants = {
  LOCALSTORAGE_KEYS: {
    SPOTIFY: "SS_Spotify",
    AUTH_MEDIA: "",
    LYRICS_TWOCOLMODE: "Lyrics-TwoColMode",
    LYRICS_FONTSIZE: "Lyrics-FontSize",
    LYRICS_TEXTALIGN: "Lyrics-TextAlign",
    LYRICS_FONTSETTINGS: "Lyrics-FontSettings",
    CHORDS_FONTSETTINGS: "Chords-FontSettings",
    CHORDS_TWOCOLMODE: "ss_chords_twocolmode",
    CHORDS_CHARTTYPE: "Chords-ChartType",
    SHEETMUSIC_AUTOSCROLL: "SheetMusic-AutoScroll",
    TOP100_DISABLE_WELCOME_MODAL: "Top100-DisableWelcomeModal",
  },
  CHART_BASE_URL: "https://cclitop100.ccli.com/charts",
  SPOTIFY: {
    CLIENT_ID: "d346144d2fbe411a9ffc8e061145d764",
  },
};
