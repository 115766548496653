<script setup lang="ts">
import { SearchCategory } from "@/assets/json/search-categories";
import SearchInput from "./SearchInput.vue";
import SearchInputCategories from "./SearchInputCategories.vue";

const beta = useBetaStore();
const router = useRouter();
const route = useRoute();
const mobile = useMobileStore();
const querySearch = ref();
const path = ref();
const search = ref("");
const category = ref(SearchCategory.All);

const searchInput = ref<
  InstanceType<typeof SearchInput> | InstanceType<typeof SearchInputCategories>
>();

onMounted(() => {
  querySearch.value = router.currentRoute.value.query.search;
  search.value = querySearch.value || "";
  path.value = router.currentRoute.value.fullPath;
});

function onFormSubmit() {
  if (!search.value) {
    return;
  }
  if (!beta.features.lyricVideoMVP) {
    router.push(`/search/results?search=${encodeURIComponent(search.value)}`);
  } else {
    if (category.value == SearchCategory.LyricVideos) {
      router.push(
        `/lyric-videos/videos?search=${encodeURIComponent(search.value)}`,
      );
    } else if (category.value == SearchCategory.Songs) {
      router.push(`/search/results?search=${encodeURIComponent(search.value)}`);
    } else {
      router.push(
        `/search/results?search=${encodeURIComponent(search.value)}&cat=all`,
      );
    }
  }
}

async function showMobileSearch() {
  mobile.setSearchBarFocus(true);
  mobile.toggleMobileNav(false);
  await nextTick();
  searchInput.value?.focus();
}

function hideMobileSearch() {
  setTimeout(() => {
    if (document.activeElement?.id !== "search-icon") {
      mobile.setSearchBarFocus(false);
    }
  }, 200);
}

function updateSearchValue() {
  if (!route.fullPath.includes("search=")) {
    search.value = "";
  }
}

watch(
  () => router.currentRoute.value.query.search,
  () => updateSearchValue(),
);

const hasFocus = computed(() => {
  return mobile.searchBarFocus;
});
</script>

<template>
  <div class="flex-1">
    <SearchInputCategories
      v-if="beta.features.lyricVideoMVP"
      ref="searchInput"
      v-model:search="search"
      v-model:category="category"
      class="search-bar"
      :class="{ 'has-focus': hasFocus }"
      @blur="hideMobileSearch"
      @submit="onFormSubmit"
    />
    <SearchInput
      v-else
      ref="searchInput"
      v-model="search"
      class="search-bar"
      :class="{ 'has-focus': hasFocus }"
      @blur="hideMobileSearch"
      @submit="onFormSubmit"
    />
    <div class="lg:hidden">
      <RoundIconButton
        v-if="!hasFocus"
        icon="fa-light fa-magnifying-glass"
        :title="$t('SV.LIT_SearchSongSelect')"
        @click="showMobileSearch"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.has-focus {
  @apply block;
}
/*.search-btn {
  @apply ml-auto flex aspect-square items-center justify-center rounded-full bg-base-200 p-3 leading-4 lg:hidden;
}*/
</style>
