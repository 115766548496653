import { Profile } from "@/models/Profile";
import { LoadStatus } from "@/models/frontend-only/LoadStatus";
import { StorageSerializers } from "@vueuse/core";

export const useProfileStore = defineStore("profile", () => {
  const profile = useLocalStorage<Profile | null>("ss-profile", null, {
    serializer: StorageSerializers.object,
  });

  const api = useApiStore();
  const app = useAppStore();
  const home = useHomeStore();
  const route = useRoute();
  const beta = useBetaStore();
  const enableInviteUser = computed(
    () => beta.features.inviteUser && profile.value?.isAdmin,
  );
  const translate = useTranslationStore();
  const visibility = useDocumentVisibility();
  const status = ref<LoadStatus>(LoadStatus.Loading);
  let profilePromise: Promise<unknown> | null = null;

  const features = computed(() => profile.value?.featureToggles);

  watch(visibility, (visibility: "visible" | "hidden") => {
    if (
      visibility === "visible" &&
      profile.value?.signedIn &&
      route.path === "/" &&
      showUrgentAnnouncementsOnly.value
    ) {
      getProfile();
    }
  });

  async function getProfile(
    _signedIn?: boolean,
    _level?: string,
    firstTime?: boolean,
  ) {
    if (
      !profilePromise ||
      _signedIn !== undefined ||
      status.value !== LoadStatus.Loading
    ) {
      profilePromise = _getProfile(_signedIn, _level, firstTime);
    }
    await profilePromise;
    await translate.init();
  }

  async function _getProfile(
    _signedIn?: boolean,
    _level?: string,
    firstTime?: boolean,
  ) {
    try {
      if (firstTime && (window as any).initialSSProfile) {
        profile.value = (window as any).initialSSProfile;
      } else {
        const response = await api.get("/user/GetProfileDetails");
        profile.value = response;

        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
          subscriptionLevel: profile.value?.subscriptionLevel || "",
          userId: profile.value?.userId || "",
          orgId: profile.value?.orgId || "",
        });
      }

      RaygunFunctions.setUser(profile.value?.userId || null);
      if (profile.value && profile.value.signedIn) {
        beta.init(profile.value.betaToggle || false);
      }

      status.value = LoadStatus.Ready;
    } catch (err) {
      status.value = LoadStatus.Error;
      throw err;
    }
  }

  const profileAttentionUrl = computed(() => {
    if (!profile.value?.profileAttentionUrl) {
      return "";
    }
    return updateQueryParamOnUrl(
      profile.value.profileAttentionUrl,
      "returnUrl",
      window.location.origin + route.fullPath,
    );
  });

  const signInUrl = computed(() => {
    if (!profile.value?.signInUrl) {
      return "";
    }
    return updateQueryParamOnUrl(
      profile.value.signInUrl,
      "returnUrl",
      window.location.origin + route.fullPath,
    );
  });

  const signUpUrl = computed(() => {
    if (!profile.value?.signUpUrl) {
      return "";
    }
    return updateQueryParamOnUrl(
      profile.value.signUpUrl,
      "returnUrl",
      window.location.origin + route.fullPath,
    );
  });

  const signOutUrl = computed(() => {
    if (!profile.value?.signOutUrl) {
      return "";
    }
    return updateQueryParamOnUrl(
      profile.value.signOutUrl,
      "returnUrl",
      window.location.origin,
    );
  });

  const switchOrgUrl = computed(() => {
    if (!profile.value?.switchOrgUrl) {
      return "";
    }
    return updateQueryParamOnUrl(
      profile.value.switchOrgUrl,
      "returnUrl",
      window.location.origin,
    );
  });

  // Helps to ensure server build is the same.
  async function getPing() {
    await api.get("/user/Ping");
  }

  getProfile(undefined, undefined, true);

  //get ping every 5 minutes. Helps to ensure server build is the same.;
  if (!app.isMobileApp) {
    setInterval(() => getPing(), 1000 * 60 * 15);
  }
  setInterval(() => getProfile(), 1000 * 60 * 30);

  const showExpiredMessage = computed(
    () =>
      home.status === LoadStatus.Ready &&
      profile.value?.signedIn &&
      profile.value?.displayExpiredMessage,
  );

  const showExpiringSoonMessage = computed(
    () =>
      home.status === LoadStatus.Ready &&
      profile.value?.signedIn &&
      profile.value?.displayExpiringSoonMessage &&
      !showExpiredMessage.value,
  );

  const showProfileNeedsAttentionMessage = computed(
    () =>
      home.status === LoadStatus.Ready &&
      profile.value?.signedIn &&
      profile.value?.profileAttentionUrl &&
      !showExpiredMessage.value &&
      !showExpiringSoonMessage.value,
  );

  const showUrgentAnnouncementsOnly = computed(
    () =>
      home.status === LoadStatus.Ready &&
      (showExpiredMessage.value ||
        showExpiringSoonMessage.value ||
        showProfileNeedsAttentionMessage.value),
  );

  function signIn() {
    //if (app.isMobileApp) {
    //  mobile.openBrowser(signInUrl.value);
    //} else {
    window.location.href = signInUrl.value;
    //}
  }

  return {
    profile,
    getProfile,
    profilePromise,
    getPing,
    features,
    signInUrl,
    profileAttentionUrl,
    signUpUrl,
    signOutUrl,
    switchOrgUrl,
    status,
    showExpiredMessage,
    showProfileNeedsAttentionMessage,
    showUrgentAnnouncementsOnly,
    showExpiringSoonMessage,
    enableInviteUser,
    signIn,
  };
});
