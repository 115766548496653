import AvailableLyricVideos from "./AvailableLyricVideos.json";
import LYRIC_VIDEOS_ENABLED_ORG_IDS from "./OrgIdsWithEnabledLyricVideos.json";
export interface LyricVideoDescription {
  label: string;
  description: string;
}

export interface LyricVideoItem {
  id: string;
  vimeoId: string;
  internalTitle: string;
  title: string;
  belowVideoDescriptions: LyricVideoDescription[];
  sideColumnDescription: string[];
  url?: string;
  mp4hd1080p?: string;
  duration: number;
  width: number;
  height: number;
  thumbnail: string;
}

export interface LyricVideo {
  id: string;
  title: string;
  description: string;
  albumCoverUrl: string;
  availableVideos: LyricVideoItem[];
}
export const AllLyricVideos: LyricVideo[] = AvailableLyricVideos;
export const LyricVideosEnabledOrgIds = LYRIC_VIDEOS_ENABLED_ORG_IDS;
