<script setup lang="ts">
const store = useBusyStore();

const complete = ref(false);
const afterComplete = ref(false);

watch(
  () => store.busy,
  () => {
    if (!store.busy) {
      complete.value = true;
      setTimeout(() => (complete.value = false), 500);
    }
  },
);

watch(complete, () => {
  if (!complete.value) {
    afterComplete.value = true;
    setTimeout(() => (afterComplete.value = false), 500);
  }
});
</script>

<template>
  <div id="BusyOverlay">
    <div
      class="bar"
      :class="{
        'bar-state-loading': store.busy,
        'bar-state-complete': complete,
        'bar-state-after-complete': afterComplete,
      }"
    />
  </div>
</template>

<style scoped lang="postcss">
#BusyOverlay {
  @apply fixed top-0 left-0 right-0 h-2 pointer-events-none;
  z-index: 10000;
}
.bar {
  @apply h-full bg-primary w-0 opacity-0 rounded-r-full;
  transition:
    0.1s width ease,
    0.1s opacity ease;

  box-shadow: 0 0 10px rgba(0, 163, 224, 0.75);
}
.bar-state-loading {
  @apply opacity-100;

  transition:
    10s width ease,
    0.1s opacity ease;
  width: 90%;
}
.bar-state-complete {
  @apply w-full opacity-100;
  transition:
    0.2s width ease,
    0.5s opacity ease;
}
.bar-state-after-complete {
  @apply w-full opacity-100;
  transition: none;
}
</style>
