import { LyricVideosEnabledOrgIds } from "@/assets/json/LyricVideos";

export const useLyricVideosStore = defineStore("lyric-videos", () => {
  const enableFeature = ref(
    import.meta.env.VITE_ENABLE_LYRIC_VIDEO_TEST === "1",
  );

  const alwaysEnableForEnv = ref(
    import.meta.env.VITE_ENABLE_LYRIC_VIDEO_TEST_ALWAYS_SHOW === "1",
  );

  const profile = useProfileStore();
  const orgId = computed(() => profile.profile?.orgId?.toLowerCase() || "");
  const enabledOrg = computed(
    () =>
      (orgId.value &&
        LyricVideosEnabledOrgIds.map((id) => id.toLowerCase()).includes(
          orgId.value,
        )) ||
      profile.profile?.isTestOrg,
  );

  const enable = computed(
    () => enableFeature.value && (alwaysEnableForEnv.value || enabledOrg.value),
  );

  return { enable, enableFeature, alwaysEnableForEnv };
});
