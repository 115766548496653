export enum SearchCategory {
  All = "All",
  Songs = "Songs",
  LyricVideos = "LyricVideos",
}

export const categoryLUT = [
  {
    label: "All",
    value: SearchCategory.All,
    icon: "fa-light fa-magnifying-glass",
  },
  { label: "Songs", value: SearchCategory.Songs, icon: "fa-light fa-music" },
  {
    label: "Lyric Videos",
    value: SearchCategory.LyricVideos,
    icon: "fa-light fa-film",
  },
];
