<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    title?: string;
    icon?: string;
    variant?: "error" | "warning" | "success" | "info";
    disableShake?: boolean;
    closable?: boolean;
  }>(),
  {
    title: "",
    variant: "error",
    disableShake: false,
  },
);

const display = ref(true);
const alertClasses = computed<string>(() => {
  return `alert-${props.variant} ${props.disableShake ? "" : "alert-shake"}`;
});

const icon = computed(() => {
  if (props.variant === "error") {
    return "fa-light fa-exclamation-triangle";
  } else if (props.variant === "warning") {
    return "fa-light fa-exclamation-circle";
  } else if (props.variant === "success") {
    return "fa-light fa-check";
  } else if (props.variant === "info") {
    return "fa-kit fa-ccli-party text-primary";
  } else {
    return "fa-light fa-info-circle";
  }
});

async function restartAnimation() {
  display.value = false;
  await nextTick();
  display.value = true;
}

defineEmits<{
  (e: "close"): void;
}>();

defineExpose({
  restartAnimation,
});
</script>

<template>
  <!-- alert-success alert-warning alert-error -->
  <div
    v-if="display"
    class="alert"
    :class="alertClasses"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
  >
    <div class="alert-start">
      <FAIcon
        :class="icon"
        class="fa-fw"
      />
      <div class="text-content">
        <div
          v-if="title"
          class="title"
        >
          {{ title }}
        </div>
        <slot />
      </div>
    </div>
    <div class="alert-end">
      <slot name="footer"></slot>
      <button
        v-if="closable"
        type="button"
        class="btn-close btn-close-white btn-sm"
        :aria-label="$t('G.LIT_Close')"
        @click="$emit('close')"
      >
        <FAIcon class="fa-light fa-times" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.alert {
  @apply flex items-center justify-between py-3 text-light-100;
}
.alert.alert-success {
  @apply bg-green-600;
}
.alert.alert-info {
  @apply border-light-700 bg-light-100 text-gray-700;
}
:deep() {
  i,
  svg {
    @apply text-2xl max-md:hidden;
  }
}
.alert-start {
  @apply flex items-center gap-3;
}
.text-content {
  @apply text-left;
}
@media screen(max-md) {
  .alert {
    @apply block text-center;
  }
  .text-content {
    @apply w-full text-center;
  }
  .alert-end {
    @apply mt-2;
  }
}
</style>
