import { createI18n } from "vue-i18n";
import enUSTranslations from "@/assets/locales/en-US.json";

const initialTranslations = {
  "en-US": escapePipelineInTranslations(enUSTranslations),
};

const i18nOptions = {
  globalInjection: true,
  legacy: false,
  locale: "en-US",
  fallbackLocale: "en-US",
  messages: initialTranslations,
};

export const i18n = createI18n(i18nOptions);
