<script setup lang="ts">
const SignedInProfileButton = defineAsyncComponent(
  () => import("@/components/Framing/SignedInProfileButton.vue"),
);
const mobile = useMobileStore();
const store = useProfileStore();
</script>

<template>
  <div
    v-if="store.profile"
    :class="{ 'search-focused': mobile.searchBarFocus }"
    class="profile-nav"
  >
    <div v-if="store.profile.signedIn">
      <SignedInProfileButton />
      <Button
        v-if="
          store.profile.signedIn && store.profile.featureToggles.enablePricing
        "
        icon-start="fa-solid fa-cart-shopping"
        variant="orange"
        to="/about/pricing"
        id="ProfileNavUpgradeLink"
        class="upgrade-link"
      >
        {{ $t("S.LIT_Upgrade") }}
      </Button>
    </div>
    <div
      v-else
      class="profile-nav-unsigned-user"
    >
      <RoundIconButton
        icon="fa-light fa-right-to-bracket"
        :title="$t('S.LIT_SignInTC')"
        @click.stop.prevent="store.signIn()"
        class="round-sign-in-btn"
      />
      <Button
        @click.stop.prevent="store.signIn()"
        id="ProfileNavSignInLink"
        variant="light"
        class="profile-btn"
      >
        {{ $t("S.LIT_SignInTC") }}
      </Button>
      <Button
        variant="orange"
        :href="store.signUpUrl"
        icon-start="fa-solid fa-user-plus"
        id="ProfileNavSignUpLink"
        data-purchase-level="Free"
        class="profile-btn"
      >
        {{ $t("S.LIT_SignUpTC") }}
        <span class="hidden md:visible">{{ $t("SV.LIT_ForFree") }}</span>
      </Button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.profile-nav-unsigned-user {
  @apply flex gap-2;
}
#ProfileNavSignUpLink {
  @apply max-md:hidden;
}
.profile-btn {
  @apply max-md:hidden;
}
.round-sign-in-btn {
  @apply md:hidden;
}
.upgrade-link {
  @apply max-md:hidden;
}
</style>
