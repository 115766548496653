<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    to?: string;
    href?: string;
    icon?: string;
    iconEnd?: string;
    title?: string;
    iconStart?: string;
    target?: string;
    block?: boolean;
    small?: boolean;
    placeholder?: boolean;
    busy?: boolean;
    variant?:
      | "gradient-blue"
      | "primary"
      | "secondary"
      | "blue"
      | "orange"
      | "orange-2"
      | "blank"
      | "white"
      | "light"
      | "red"
      | "transparent-white"
      | "ghost";
  }>(),
  {
    target: undefined,
    href: "",
    to: "",
    iconEnd: "",
    icon: "",
    iconStart: "",
    busy: false,
    variant: "light",
    title: undefined,
  },
);

const classes = computed(() => {
  const c = [];
  if (props.placeholder) {
    c.push("placeholder");
  }
  if (props.variant) {
    c.push(`btn-variant-${props.variant}`);
  }
  if (props.small) {
    c.push("btn-sm");
  }
  if (props.block) {
    c.push(`btn-block`);
  }
  if (props.busy) {
    c.push("busy");
  }
  return c.join(" ");
});

const component = computed(() => {
  if (props.placeholder) {
    return "span";
  }
  if (props.href) {
    return "a";
  }
  if (props.to) {
    return "router-link";
  }
  return "button";
});

const attributes = computed(() => {
  if (component.value === "span" || props.busy) {
    return {};
  }
  if (component.value === "a") {
    return {
      href: props.href,
      title: props.title,
      target: props.target,
    };
  }
  if (component.value === "router-link") {
    return {
      to: props.to,
      title: props.title,
    };
  }
  return {
    type: "button",
    title: props.title,
  };
});
</script>

<template>
  <component
    :is="component"
    v-bind="attributes"
    :class="classes"
    class="btn"
  >
    <FAIcon
      v-if="busy"
      class="fa-fw fa-light fa-spinner-third fa-spin"
    ></FAIcon>

    <ButtonContents
      :icon-start="iconStart"
      :icon-end="iconEnd"
      :icon="icon"
      ><slot
    /></ButtonContents>
  </component>
</template>

<style scoped lang="postcss">
span {
  @apply pointer-events-none;
}
.btn {
  @apply no-animation inline-flex flex-nowrap items-center whitespace-nowrap rounded-full border-0 px-6 py-3 font-display font-bold uppercase leading-4 outline-4 outline-offset-0 transition-all;
  letter-spacing: 0.05em;
  font-size: 16px;
  height: unset;
  min-height: unset;
  &.busy {
    @apply pointer-events-none;
  }
}

a,
button {
  &:hover {
    @apply brightness-95;
  }
  &:focus-visible {
    @apply outline-4 outline-offset-0;
  }
}

:deep() {
  i,
  svg {
    @apply opacity-50;
  }
}

.btn-sm {
  @apply py-2 text-sm;
  &:deep() {
    i,
    svg {
      @apply text-sm;
    }
  }
}

.btn-variant-blank {
  @apply hover:bg-gray-700;
}

.btn-variant-light {
  @apply bg-light-500;
  &:hover {
    @apply bg-light-700;
  }
  &:focus-visible {
    @apply outline-light-800;
  }
}

.btn-variant-orange {
  @apply bg-ccli-orange3 text-white hover:bg-orange-600;
  &:focus-visible {
    @apply outline-orange-400;
  }
}
.btn-variant-red {
  @apply bg-ccli-red text-white hover:brightness-90;
  &:focus-visible {
    @apply outline-red-400;
  }
}

.btn-variant-primary {
  @apply bg-primary text-white hover:bg-primary;
  &:focus-visible {
    @apply outline-blue-300;
  }
}
.btn-variant-secondary {
  @apply bg-secondary text-white;
  &:hover,
  &:focus-visible {
    @apply brightness-90;
  }
}
.btn-variant-transparent-white {
  @apply border-2 border-light-100 bg-transparent text-light-100;
  &:hover,
  &:focus-visible {
    @apply bg-light-100 text-black;
  }
  &:focus-visible {
    @apply outline-offset-2 outline-light-100;
  }
  :deep() {
    i,
    svg {
      @apply opacity-100;
    }
  }
}
.btn-variant-ghost {
  @apply border-transparent bg-transparent font-sans text-base font-normal tracking-normal text-primary shadow-none outline-none;
  &:hover {
    @apply bg-base-200;
  }
  text-transform: unset;
}
</style>
