export const useBusyStore = defineStore('busy', () => {
  const busy = ref(false);

  function showBusy() {
    busy.value = true;
  }

  function hideBusy() {
    busy.value = false;
  }

  return { busy, showBusy, hideBusy };
});
