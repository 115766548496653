type ModalId =
  | "WelcomeModal"
  | "SignInModal"
  | "UpgradeRequiredModal"
  | "SSRequiredModal"
  | "SubscriberFeatureModal"
  | "UnsupportedBrowserModal"
  | "ShareModal"
  | "PreferenceModal"
  | "SelectListModal"
  | "InviteUserModal"
  | "ChordProInfoModal"
  | "AddSongModal"
  | "MaxSongModal"
  | "ConfirmDeleteSetlistModal"
  //| "MediaPlayerSettingsModal"
  | "NavGuardModal"
  | "NewListModal"
  | "RenameListModal"
  | "SelectListModal"
  | "SelectProfileModal"
  | "UniqueSongsAboutModal"
  | "ConfirmDeleteFavoritesListModal"
  | "AddLyricVideosModal"
  | "AddFilterDropdownModal"
  | "SelectEmailModal"
  | "ConfirmResetSettingsModal"
  | "ConfirmClearStylesModal"
  | "NewFeaturesModal"
  | "ConfirmDisconnectIntegrationModal"
  | "CCLITop100Modal"
  | "SetlistDetailsModal"
  | "CCLITopSongsModal";

export const useModalStore = defineStore("modal", () => {
  const enabledModal = ref<ModalId | "">("");
  const showBusy = ref(false);
  const props = ref<any>({});

  watch(enabledModal, async () => {
    if (enabledModal.value) {
      await nextTick();
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  });

  function onModalMount() {
    showBusy.value = false;
  }

  function showModal(modalId: ModalId, inProps?: { [key: string]: any }) {
    detectScrollbarWidth();
    props.value = inProps || {};
    enabledModal.value = modalId;
    showBusy.value = true;
  }

  function hideModal() {
    emitter.emit("hideAllModals");
    setTimeout(disposeAllModals, 300);
  }

  function disposeAllModals() {
    enabledModal.value = "";
  }

  return {
    showModal,
    hideModal,
    enabledModal,
    disposeAllModals,
    showBusy,
    onModalMount,
    props,
  };
});
