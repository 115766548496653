const productionMode = import.meta.env.MODE === "production";

declare global {
  interface Window {
    debug: any;
  }
}

export function useDebug() {
  function log(...args: any[]) {
    if (!productionMode || window.debug) {
      console.log(...args);
    }
  }
  function trace(...args: any[]) {
    if (!productionMode || window.debug) {
      console.trace(...args);
    }
  }

  return { log, trace };
}
