<script setup lang="ts">
import { RouterLink } from "vue-router";

withDefaults(
  defineProps<{
    to?: string;
    href?: string;
    icon?: string;
    newBadge?: boolean;
    exact?: boolean;
    placeholder?: boolean;
    queryList?: string;
    gaLabel?: string;
    noLink?: boolean;
  }>(),
  {
    to: "",
    href: "",
    icon: "",
    newBadge: false,
    factive: false,
    placeholder: false,
    queryList: "",
  },
);

const route = useRoute();
const routerlink = ref<InstanceType<typeof RouterLink>>();

async function checkExpand() {
  await nextTick();
  if (
    ((routerlink.value as any)?.$el as HTMLAnchorElement)?.classList.contains(
      "active",
    )
  ) {
    toggleExpand(true);
  } else {
    toggleExpand(false);
  }
}
checkExpand();
watch(route, checkExpand);

const emit = defineEmits<{
  (e: "click"): void;
}>();

const expand = ref(false);

function toggleExpand(forceValue?: boolean) {
  if (forceValue !== undefined) {
    expand.value = forceValue;
  } else {
    expand.value = !expand.value;
  }
}
</script>

<template>
  <div class="site-nav-link-wrapper">
    <span
      v-if="placeholder"
      class="site-nav-link"
    >
      <span
        class="placeholder"
        style="width: 200px"
      />
    </span>
    <span
      v-else-if="noLink"
      class="site-nav-link"
    >
      <FAIcon
        v-if="icon"
        class="fa-fw nav-icon icon-start"
        :class="icon"
      />
      <slot />
      <Badge
        v-if="newBadge"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </span>
    <router-link
      v-else-if="to"
      ref="routerlink"
      :to="to"
      :active-class="exact ? undefined : 'active'"
      :class="{
        active: $route.fullPath === to || $route.query.list === queryList,
        expand: expand,
      }"
      class="site-nav-link"
      :data-menu-item="gaLabel"
    >
      <FAIcon
        v-if="icon"
        class="fa-fw nav-icon icon-start"
        :class="icon"
      />
      <div>
        <slot />
      </div>
      <button
        v-if="$slots.menu"
        type="button"
        aria-label="Toggle visibility of submenu"
        class="expand-icon-btn btn btn-circle ms-auto"
        @click.stop.prevent="() => toggleExpand()"
      >
        <FAIcon class="fa-fw nav-icon fa-light fa-chevron-right expand-icon" />
      </button>
      <Badge
        v-if="newBadge"
        class="ms-2"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </router-link>

    <a
      v-else-if="href"
      :href="href ?? '#'"
      target="_blank"
      class="site-nav-link"
      :data-menu-item="gaLabel"
    >
      <FAIcon
        v-if="icon"
        class="fa-fw icon-start nav-icon"
        :class="icon"
      />
      <slot />
      <Badge
        v-if="newBadge"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </a>

    <button
      v-else
      class="site-nav-link"
      :data-menu-item="gaLabel"
      type="button"
      @click="$emit('click')"
    >
      <FAIcon
        v-if="icon"
        class="fa-fw icon-start nav-icon"
        :class="icon"
      />
      <slot />
      <Badge
        v-if="newBadge"
        variant="primary"
      >
        {{ $t("G.LIT_New") }}
      </Badge>
    </button>
    <ul
      v-if="$slots.menu"
      class="sublinks"
    >
      <slot name="menu" />
    </ul>
  </div>
</template>

<style scoped lang="postcss">
.site-nav-link {
  @apply flex w-full items-center gap-2 rounded-full px-5 py-3 leading-4 text-gray-600 transition-all;
}
button.site-nav-link,
a.site-nav-link {
  &:hover,
  &:focus {
    @apply bg-light-700;
  }

  &.active {
    @apply bg-primary font-medium text-light-100;
  }
}

.sublinks {
  @apply ml-7 mt-2 transition-all;
}

.site-nav-link ~ .sublinks {
  opacity: 0;
  height: 0;
  pointer-events: none;
}
.site-nav-link.expand ~ .sublinks {
  opacity: 1;
  height: unset;
  pointer-events: unset;
}

.expand-icon {
  @apply bg-opacity-50 text-base-content transition-all;
}
.site-nav-link.expand .expand-icon {
  @apply rotate-90;
}
.icon-start {
  @apply mr-2;
}
.btn-circle {
  @apply border-none bg-base-100/25 text-base-content;
  height: 1.5rem;
  width: 1.5rem;
  min-height: unset;
}

@media screen {
  html.dark {
    button.site-nav-link,
    a.site-nav-link {
      @apply text-gray-300;
      &.active {
        @apply text-light-100;
      }
      &:hover:not(.active),
      &:focus {
        @apply text-gray-100;
        @apply bg-gray-800;
      }
    }
    .expand-icon-btn {
      @apply bg-gray-850/25 text-gray-100;
    }
  }
}
</style>
