<script setup lang="ts"></script>

<template>
  <div class="container">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.container {
  @apply relative mx-auto max-w-[1536px] px-2 py-5 md:px-5;
}
</style>
