<script setup lang="ts">
const store = useMobileStore();
const modals = useModalStore();
const profileStore = useProfileStore();
const lyricVideos = useLyricVideosStore();
const betaStore = useBetaStore();
const chartStore = useChartStore();

chartStore.init();

const { width } = useWindowSize();
const enableDOM = ref(false);
const show = ref(false);
let timer: number | null = null;
async function checkEnableDOM() {
  timer !== null && window.clearTimeout(timer);
  timer = null;

  if (width.value >= 1024) {
    enableDOM.value = true;
    show.value = true;
  } else if (store.showMobileNav) {
    enableDOM.value = true;
    await asyncWait(50);
    show.value = true;
  } else {
    show.value = false;
    timer = window.setTimeout(() => {
      timer = null;
      enableDOM.value = false;
    }, 200);
  }
}

checkEnableDOM();
watch(() => [width.value, store.showMobileNav], checkEnableDOM);

const enableEaster = import.meta.env.VITE_ENABLE_HOME_TOP_EASTER === "1";
const enableNewSSMessaging = import.meta.env.VITE_ENABLE_NEW_SS_MSG === "1";

const enableLyricVideoMVP = computed(() => betaStore.features.lyricVideoMVP);
</script>

<template>
  <nav
    v-if="enableDOM"
    id="SiteNav"
    :class="{ 'show-mobile': show }"
  >
    <div class="site-nav-upper">
      <div class="logo-wrapper">
        <SiteLogo class="logo" />
      </div>
    </div>
    <div class="flex-1 overflow-y-auto">
      <NewSiteToggle
        v-if="enableNewSSMessaging"
        class="mb-3"
      />
      <div class="px-2">
        <section class="site-nav-section">
          <!-- <SiteNavLink
            to="/"
            icon="fa-light fa-home"
            exact
            :ga-label="$t('G.LIT_Home')"
          >
            {{ $t("G.LIT_Home") }}
          </SiteNavLink> -->

          <SiteNavLink
            icon="fa-light fa-house"
            ga-label="Home"
            to="/"
          >
            {{ $t("G.LIT_Home") }}
            <template #menu>
              <template v-if="!betaStore.features.ccliTop100New">
                <SiteNavSubLink
                  v-if="profileStore.features?.enableCCLITopSongs"
                  to="/search/topsongslists"
                  ga-label="CCLI Top Songs"
                >
                  <div>
                    {{ $t("G.LIT_CCLITopSongs") }}
                    <sup class="trademark">&reg;</sup>
                  </div>
                </SiteNavSubLink>

                <!-- <SiteNavSubLink
                  v-if="enableSeasonalItems"
                  to="/search/results?list=topthemed_Christmas"
                  exact
                  :subitem="profileStore.features?.enableCCLITopSongs"
                  query-list="topthemed_Christmas"
                  >Top Songs for Christmas</SiteNavSubLink
                > -->
                <!-- <SiteNavSubLink
                    v-if="profileStore.features?.enableCCLITopSongs"
                    to="/search/results?list=custom_Christmas-Simplified"
                    exact
                    subitem
                    query-list="custom_Christmas-Simplified"
                    >Simplified Christmas Songs</SiteNavSubLink
                  > -->

                <SiteNavSubLink
                  v-if="enableEaster"
                  to="/search/results?sortBy=popularity&list=topthemed_Easter"
                  exact
                  :subitem="profileStore.features?.enableCCLITopSongs"
                  query-list="eastersongs"
                  ga-label="Top Easter Songs"
                >
                  {{ $t("SV.LIT_TopEasterSongs") }}
                </SiteNavSubLink>
                <SiteNavSubLink
                  to="/search/results?list=popular"
                  exact
                  :subitem="profileStore.features?.enableCCLITopSongs"
                  query-list="popular"
                  ga-label="Popular Songs"
                >
                  {{ $t("S.LIT_Popular") }}
                </SiteNavSubLink>
                <SiteNavSubLink
                  v-if="profileStore.features?.enableCCLITop100"
                  to="/search/results?list=top100"
                  exact
                  :subitem="profileStore.features?.enableCCLITopSongs"
                  query-list="top100"
                  ga-label="CCLI Top 100"
                >
                  <div>
                    {{ $t("SV.LIT_CCLITop100") }}
                    <sup class="trademark">&reg;</sup>
                  </div>
                </SiteNavSubLink>
                <SiteNavSubLink
                  v-if="lyricVideos.enable"
                  to="/lyric-videos"
                  subitem
                  ga-label="Lyric Videos"
                >
                  Lyric Videos
                  <span class="text-xs font-bold italic text-primary"
                    >TEST</span
                  >
                </SiteNavSubLink>
                <SiteNavSubLink
                  v-if="enableLyricVideoMVP"
                  to="/lyric-videos"
                  subitem
                  ga-label="Lyric Videos"
                >
                  Lyric Videos
                  <span class="text-xs font-bold uppercase italic text-primary"
                    >Beta</span
                  >
                </SiteNavSubLink>
              </template>
              <template v-else>
                <SiteNavSubLink
                  v-if="chartStore.defaultCountry?.ChartType === 'ccli-top-100'"
                  to="/ccli-top-100"
                >
                  <div>
                    {{ $t("SV.LIT_CCLITop100") }}
                    <sup class="trademark">&reg;</sup>
                  </div>
                  <div class="text-xs italic opacity-50">
                    {{ $t("SV.LIT_FormerlyPopularSongs") }}
                  </div>
                </SiteNavSubLink>
                <SiteNavSubLink
                  v-else
                  to="/ccli-top-songs"
                >
                  <div>
                    {{ $t("S.LIT_CCLITopSongs") }}
                    <sup class="trademark">&reg;</sup>
                  </div>
                  <div class="text-xs italic opacity-50">
                    {{ $t("SV.LIT_FormerlyPopularSongs") }}
                  </div>
                </SiteNavSubLink>
                <SiteNavSubLink
                  v-if="enableLyricVideoMVP"
                  to="/lyric-videos"
                  ga-label="Lyric Videos"
                >
                  Lyric Videos
                  <span class="text-xs font-bold uppercase italic text-primary"
                    >Beta</span
                  >
                </SiteNavSubLink>
                <SiteNavSubLink
                  v-if="lyricVideos.enable"
                  to="/lyric-videos"
                  ga-label="Lyric Videos"
                >
                  Lyric Videos
                  <Badge variant="primary">Test</Badge>
                </SiteNavSubLink>
              </template>

              <SiteNavSubLink
                to="/search/themes"
                ga-label="Themes"
              >
                {{ $t("S.LIT_Themes") }}
              </SiteNavSubLink>
              <SiteNavSubLink
                v-if="profileStore.features?.enableLiturgy"
                to="/search/liturgycalendar"
                ga-label="Liturgy Planning"
              >
                {{ $t("S.LIT_LiturgySectionPlanning") }}
              </SiteNavSubLink>

              <SiteNavSubLink
                v-if="profileStore.features?.enableSongsByLanguage"
                to="/search/language"
                ga-label="Songs By Language"
              >
                {{ $t("SV.LIT_SongsByLanguage") }}
              </SiteNavSubLink>

              <SiteNavSubLink
                to="/search/results?list=publicdomain"
                exact
                query-list="publicdomain"
                ga-label="Public Domain Songs"
              >
                {{ $t("S.LIT_PublicDomainSongs") }}
              </SiteNavSubLink>
            </template>
          </SiteNavLink>

          <SiteNavLink
            to="/favorites"
            icon="fa-light fa-heart"
            ga-label="Favorites"
          >
            {{ $t("SV.LIT_YourLibrary") }}
          </SiteNavLink>
          <SiteNavLink
            to="/account/activity"
            icon="fa-light fa-clock-rotate-left"
            ga-label="Activity"
          >
            {{ $t("G.LIT_Activity") }}
          </SiteNavLink>
          <SiteNavLink
            icon="fa-light fa-cog"
            ga-label="Preferences"
            @click="
              modals.showModal('PreferenceModal');
              store.showMobileNav = false;
            "
          >
            {{ $t("SV.LIT_Preferences") }}
          </SiteNavLink>
          <!-- <SiteNavLink
            icon="fa-light fa-moon-stars"
            v-if="!darkModeStore.darkMode"
            @click="darkModeStore.setTheme(true)"
          >
            Enable Dark Mode
          </SiteNavLink>
          <SiteNavLink
            icon="fa-light fa-brightness"
            @click="darkModeStore.setTheme(false)"
            v-else
          >
            Enable Light Mode
          </SiteNavLink> -->
        </section>

        <section
          v-if="
            profileStore.features?.enableLearnMore ||
            profileStore.features?.enablePricing ||
            betaStore.features.lyricVideoMVP
          "
          class="site-nav-section"
        >
          <SiteNavLink
            v-if="profileStore.features?.enableLearnMore"
            href="https://ccli.com/songselect"
            ga-label="Learn More"
          >
            {{ $t("G.LIT_LearnMore") }}
            <span class="sr-only">About SongSelect</span>
            <FAIcon class="fa-light fa-external-link ml-auto text-xs" />
          </SiteNavLink>

          <SiteNavLink
            v-if="
              profileStore.profile &&
              profileStore.profile.signedIn &&
              profileStore.profile.featureToggles.enablePricing
            "
            id="ProfileNavUpgradeLink"
            icon-start="fa-solid fa-cart-shopping"
            class="profile-btn w-full"
            to="/about/pricing"
          >
            {{ $t("S.LIT_Upgrade") }}
          </SiteNavLink>
          <SiteNavLink
            v-else-if="
              profileStore.features?.enablePricing ||
              betaStore.features.lyricVideoMVP
            "
            to="/about/pricing"
            ga-label="View Pricing"
          >
            {{ $t("S.LIT_ViewPricing") }}
          </SiteNavLink>
        </section>
        <section class="site-nav-section">
          <SiteNavLink
            v-if="profileStore.features?.eanbleLoopTopMultitracks"
            href="https://loopcommunity.com/songs?type=best-selling-songs&genre=1"
            ga-label="Top Multitracks"
          >
            <span class="sr-only">{{ $t("SV.LIT_LoopCommunity") }}</span>
            <FAIcon
              class="icon-logo fa-kit fa-loop-logo me-2 text-xl"
              :title="$t('SV.LIT_HasMultitracks')"
            />
            <p>{{ $t("SV.LIT_TopMultitracks") }}</p>
            <FAIcon class="fa-light fa-external-link ml-auto text-xs" />
          </SiteNavLink>
          <SiteNavLink
            v-if="profileStore.features?.enableWorshipTools"
            href="https://www.worshiptools.com/en-us"
            ga-label="WorshipTools"
          >
            <span class="sr-only">{{ $t("SV.LIT_WorshipTools") }}</span>
            <FAIcon
              class="icon-logo fa-kit fa-worshiptools-logo me-2 text-xl"
              :title="$t('SV.LIT_WorshipTools')"
            />
            <p>{{ $t("SV.LIT_FreeWorshipApps") }}</p>
            <FAIcon class="fa-light fa-external-link ml-auto text-xs" />
          </SiteNavLink>
        </section>
      </div>
    </div>

    <button
      type="button"
      class="close-button"
      :class="{ 'show-mobile': store.showMobileNav }"
      :aria-label="$t('G.LIT_Close')"
      @click="store.toggleMobileNav()"
    >
      <FAIcon class="fa-light fa-times" />
    </button>
  </nav>
</template>

<style scoped lang="postcss">
#SiteNav {
  width: var(--nav-width);
  max-width: var(--max-nav-width);
  @apply fixed bottom-0 left-0 top-0 flex flex-col justify-between border-r border-r-light-700 bg-white;
  z-index: 110;

  @media screen(max-lg) {
    width: var(--mobile-nav-width);
    @apply fixed bottom-0 top-0 z-50 transition-all;
    left: calc(-1 * var(--mobile-nav-width));

    &.show-mobile {
      @apply max-lg:left-0;
    }
  }
}

section {
  @apply py-1;
}

section:not(:last-of-type) {
  @apply border-b border-light-700;
}

.site-nav-section {
  @apply grid;
}

.close-button {
  @apply absolute z-50 hidden p-3 text-xl text-light-100;
  top: 0px;
  right: 0;
  transform: translateX(40px);

  @media screen(max-lg) {
    &.show-mobile {
      @apply block;
    }
  }
}

.site-nav-upper {
  @apply flex-shrink-0;
}

.site-nav-bottom {
  @apply flex-shrink-0 border-t border-light-700 shadow-lg;
}

.logo-wrapper {
  @apply flex items-center justify-center;
  /* height: var(--header-height);*/
  height: 100px;
}

.logo {
  width: 200px;
  height: 39px;
  @apply mx-auto block;
}
</style>
