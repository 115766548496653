<script setup lang="ts">
const store = useToastStore();
</script>

<template>
  <Teleport to="body">
    <div
      v-if="store.toasts.length"
      id="toastPlacement"
      class="toast toast-center"
    >
      <Alert
        v-for="t of store.toasts"
        :key="t.id"
        :variant="t.variant"
        :closable="t.closable"
        :config="t"
        @close="store.removeToast(t.id)"
        >{{ t.message }}</Alert
      >
    </div>
  </Teleport>
</template>

<style scoped lang="postcss">
.toast {
  z-index: 1000;
  @apply w-full max-w-xl;
}
</style>
