<script setup lang="ts">
const mobile = useMobileStore();
const app = useAppStore();
</script>

<template>
  <header
    id="SiteHeader"
    :class="{ 'search-focused': mobile.searchBarFocus }"
  >
    <Teleport
      v-if="!app.productionMode"
      to="body"
    >
      <div class="dev"></div>
    </Teleport>
    <Container>
      <div class="flex items-center justify-between">
        <div class="header-start">
          <MobileNavButton />
          <div class="logo-wrapper">
            <SiteLogo class="logo logo-desktop" />
            <SiteMark class="logo logo-mobile" />
          </div>
        </div>

        <div class="header-end">
          <SearchBar />
          <ProfileNav />
        </div>
      </div>
    </Container>
  </header>
</template>

<style scoped lang="postcss">
header {
  @apply fixed left-0 right-0 top-0 flex items-center bg-white drop-shadow-sm;
  height: var(--header-height);
  z-index: 30;
  /*box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);*/
  @media screen(lg) {
    margin-left: var(--nav-width);
  }
}
.dark header {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
}
.logo-wrapper {
  @apply relative;
  height: 46px;
}
.logo {
  @apply mr-4 transition-all;
  height: 46px;
}
.logo.logo-desktop {
  width: 175px;
}
header.search-focused {
  .logo-wrapper {
    @apply max-md:hidden;
  }
  .header-end {
    @apply flex-1;
  }
}

.header-start {
  @apply flex flex-shrink-0 items-center lg:hidden;
}
.header-end {
  @apply flex items-center gap-1 lg:flex-1;
}
:deep() {
  .container {
    @apply py-0;
  }
}
.desktop-header {
  @apply hidden items-center gap-2 lg:flex;
}
.dev {
  @apply fixed left-0 right-0 top-0 z-[500000] h-[3px] bg-error;
}

.logo-mobile {
  @apply hidden;
  @media screen and (min-width: 250px) and (max-width: 400px) {
    @apply block;
  }
}

.logo-desktop {
  @media screen and (max-width: 399px) {
    @apply hidden;
  }
}
</style>
