<script setup lang="ts">
const DropdownContents = defineAsyncComponent(
  () => import("@/components/Dropdown/DropdownContents.vue"),
);

defineProps<{
  label?: string;
  title?: string;
  disabled?: boolean;
  fixed?: boolean;
  disableTeleport?: boolean;
  placement?: string;
  disableContentDropdownToggle?: boolean;
  forcedContentWidth?: string;
}>();

const btn = ref<HTMLElement>();

const enableDOM = ref(false);

const emit = defineEmits<{
  (e: "open"): void;
  (e: "close"): void;
}>();

watch(enableDOM, () => {
  if (enableDOM.value) {
    emit("open");
  } else {
    emit("close");
  }
});

function toggleDropdown() {
  enableDOM.value = !enableDOM.value;
}

const contents = ref<InstanceType<typeof DropdownContents>>();

defineExpose({
  enableDOM,
  btn,
  contents,
});
</script>

<template>
  <div class="dropdown">
    <button
      ref="btn"
      type="button"
      class="btn"
      :class="{ pressed: enableDOM }"
      :disabled="disabled"
      :title="title"
      @click.stop.prevent="toggleDropdown"
    >
      <slot name="btn-label">{{ label }}</slot>
    </button>

    <DropdownContents
      v-if="enableDOM && btn && !disabled"
      ref="contents"
      :disable-teleport="disableTeleport"
      :placement="placement"
      :btn="btn"
      :forced-width="forcedContentWidth"
      @click="!disableContentDropdownToggle && toggleDropdown()"
      @click-outside="toggleDropdown()"
    >
      <slot />
    </DropdownContents>
  </div>
</template>

<style scoped lang="postcss">
.btn {
  max-height: unset;
  height: unset;
  @apply border-none;
  &.pressed,
  &:hover,
  &:focus {
    @apply bg-base-300;
  }
}
.btn:active:hover,
.btn:active:focus {
  transform: unset;
}
</style>
