import { CustomError } from "@/models/frontend-only/CustomError";
declare const rg4js: any;
const pinia = createPinia();
const app = useAppStore(pinia);

async function setUser(userId: null | string) {
  if (import.meta.env.VITE_RAYGUN_KEY && app.browserSupported) {
    await waitUntilTrue(() => !!(window as any).rg4js);
    if (!userId) {
      rg4js("setUser", {
        isAnonymous: true,
        identifier: "",
        uuid: "",
      });
    } else {
      rg4js("setUser", {
        identifier: userId,
        isAnonymous: false,
        uuid: userId,
      });
    }
  }
}

async function sendSilentError(errMsg: string, extraData: any) {
  if (app.browserSupported) {
    if (import.meta.env.VITE_RAYGUN_KEY) {
      await waitUntilTrue(() => !!(window as any).rg4js);
      rg4js("send", {
        error: new Error(errMsg),
        customData: { extraData, silentError: true },
      });
    }
  }
}

async function handleVueError(err: any, vm: any, info: string) {
  if (app.browserSupported) {
    console.error(err);
    if ((err as CustomError).ignoreError) {
      return;
    }

    if (import.meta.env.VITE_RAYGUN_KEY) {
      await waitUntilTrue(() => !!(window as any).rg4js);
      rg4js("send", {
        error: err,
        customData: { err, info },
      });
    }
  }
}

export async function raygunInit() {
  if (app.browserSupported) {
    (await import("./RaygunLoadScript")).loadRayGun();
    await waitUntilTrue(() => !!(window as any).rg4js);
    rg4js("enableCrashReporting", true);
    rg4js("apiKey", import.meta.env.VITE_RAYGUN_KEY);
    rg4js("enablePulse", false);
    rg4js("setVersion", import.meta.env.VITE_BUILD_VERSION);
    rg4js("withTags", ["js"]);
    rg4js("options", {
      captureUnhandledRejections: false,
      ignore3rdPartyErrors: true,
      saveIfOffline: true,
      ignoreAjaxAbort: true,
    });
    useDebug().log("raygun initialized");
  }
}

export const RaygunFunctions = {
  setUser,
  sendSilentError,
  handleVueError,
};
