export function updateQueryParamOnUrl(
  urlString: string,
  queryParamName: string,
  queryParamValue: string
) {
  if (!urlString) {
    return "";
  }
  let urlstr = urlString;
  if (urlstr.match(/^\//)) {
    urlstr = window.location.origin + urlstr;
  }
  const url = new URL(urlstr);
  url.searchParams.delete(queryParamName);
  url.searchParams.append(queryParamName, queryParamValue);
  return url.href;
}
