import { ChartRowItem } from "@/models/frontend-only/ChartRowItem";
import {
  ChartBadge,
  FESearchResult,
} from "@/models/frontend-only/FESearchResult";
import { ChartCountryInfo } from "@/models/frontend-only/ChartCountryInfo";
import { defineStore } from "pinia";

export const useChartStore = defineStore("chart", () => {
  const beta = useBetaStore();
  const profile = useProfileStore();

  const availableCountries = ref<ChartCountryInfo[]>([]);

  const availableCharts = computed(() => {
    const allCharts = availableCountries.value.map((c) => ({
      chartCountryCode: c.ChartCountryCode,
      chartCountryName: c.ChartCountryName,
      serviceCenter: c.ServiceCenter,
      chartType: c.ChartType,
      limitRanksTo: c.LimitRanksTo,
      visible: c.Visible,
    }));
    const charts: typeof allCharts = [];
    allCharts.forEach((c) => {
      if (
        !charts.find((cc) => cc.chartCountryCode === c.chartCountryCode) &&
        c.chartCountryCode !== null
      ) {
        charts.push(c);
      }
    });
    return sortBy(charts, (c) => c.serviceCenter);
  });

  const defaultCountry = computed(() => {
    const fallbackCountry: ChartCountryInfo = {
      Country: "US",
      ChartCountryName: "United States",
      ServiceCenter: "United States",
      ChartCountryCode: "US",
      ChartType: "ccli-top-100",
      LimitRanksTo: 100,
      Visible: true,
    };

    const country = availableCountries.value.find(
      (c) => c.Country === profile.profile?.countryCode,
    );

    return country || fallbackCountry;
  });

  function isCCLIChartAvailable(chartCountryCode: string) {
    if (
      availableCountries.value.findIndex(
        (c) => c.ChartCountryCode === chartCountryCode,
      ) === -1
    ) {
      return false;
    }
    return true;
  }

  const geoChartWeeks = ref<{ [chartCountryCode: string]: string[] }>({});

  async function getChartWeeks(chartCountryCode: string) {
    if (!isCCLIChartAvailable(chartCountryCode)) {
      console.error("Invalid country code", chartCountryCode);
      throw new Error("Invalid country code");
    }

    if (geoChartWeeks.value[chartCountryCode]) {
      return Array.from(geoChartWeeks.value[chartCountryCode]);
    }

    const data = await tokenFetch<{ WeekOf: string }[]>(
      `${AppConstants.CHART_BASE_URL}/${chartCountryCode}/chartWeeks.json`,
      true,
    );

    const dataWeeks = data.map((d) => d.WeekOf);

    geoChartWeeks.value[chartCountryCode] = dataWeeks;

    return Array.from(geoChartWeeks.value[chartCountryCode]);
  }

  async function getMostRecentChartWeek(chartCountryCode: string) {
    const chartWeeks = await getChartWeeks(chartCountryCode);
    const dates = sortBy(chartWeeks, (c) => c);
    let results: FESearchResult[] | null = null;
    let date: string = "";
    do {
      date = dates.pop() || "";
      if (!date) {
        throw new Error("No chart data available");
      }
      try {
        results = await getChartData(chartCountryCode, date);
      } catch (e) {
        console.warn("Error fetching chart data", chartCountryCode, date, e);
      }
    } while (!results || results.length === 0);
    return { date, results };
  }

  async function getAvailableCountries() {
    const data = await tokenFetch<ChartCountryInfo[]>(
      `${AppConstants.CHART_BASE_URL}/countryConfig.json`,
      true,
    );

    availableCountries.value = data.filter((c) => c.Visible);
  }

  const cachedChartData = ref<{
    [countryCode: string]: { [weekOf: string]: FESearchResult[] };
  }>({});

  async function getChartData(countryCode: string, weekOf: string) {
    if (
      cachedChartData.value[countryCode] &&
      cachedChartData.value[countryCode][weekOf]
    ) {
      return Array.from(cachedChartData.value[countryCode][weekOf]);
    }

    if (!isCCLIChartAvailable(countryCode)) {
      console.error("Invalid country code", countryCode);
      throw new Error("Invalid country code");
    }

    const chartWeeks = await getChartWeeks(countryCode);

    if (chartWeeks.indexOf(weekOf) === -1) {
      throw new Error("Invalid week of date");
    }

    const results = await tokenFetch<ChartRowItem[]>(
      `${AppConstants.CHART_BASE_URL}/${countryCode}/${weekOf}.json`,
      true,
    ).catch((err) => {
      throw err;
    });

    if (!results) {
      console.error("no results found for", countryCode, weekOf);
      throw new Error("No results found for this week");
    }

    const transformedResults: FESearchResult[] = await Promise.all(
      results.map(async (r) => {
        const badges: ChartBadge[] = [];
        if (r.IsPublicDomain) {
          badges.push("public-domain");
        }
        if (r.IsTrending) {
          badges.push("trending");
        }
        if (r.IsReEntry) {
          badges.push("re-entry");
        }
        if (r.IsNewEntry) {
          badges.push("new-entry");
        }
        if (r.IsEvergreen) {
          badges.push("evergreen");
        }

        const data: FESearchResult = {
          title: r.Title,
          authors: r.Authors.split("^"),
          slug: slugify(r.SongTitle),
          rank: r.ChartPosition,
          rankMovement: r.ChartMovement,
          weeksOnChart: r.WeeksInChart,
          songNumber: r.SongNumber.toString(),
          soundSample: {
            audioUrl: r.SoundSampleURL,
            imageUrl: r.AlbumArtURL,
          },
          productExists: {
            chords: r.HasChordSheet,
            lead: r.HasLeadSheet,
            lyrics: r.HasLyrics,
            multitracks: false,
            vocal: r.HasHymnSheet,
          },
          badges,
        };

        const songVersion = await getSongVersions(r.SongNumber.toString());
        if (songVersion) {
          data.title = songVersion.SongTitle;
          data.artist = songVersion.Artist;
          data.album = songVersion.Album;
          data.soundSample = {
            audioUrl: songVersion.SoundSampleURL,
            imageUrl: songVersion.AlbumArtURL,
          };
          data.enableSongVersion = true;
        } else {
          data.enableSongVersion = false;
        }

        return data;
      }),
    );

    if (!cachedChartData.value[countryCode]) {
      cachedChartData.value[countryCode] = {};
    }
    cachedChartData.value[countryCode][weekOf] = transformedResults;

    return Array.from(cachedChartData.value[countryCode][weekOf]);
  }

  async function init() {
    await Promise.all([getAvailableCountries()]);
  }

  if (beta.features.ccliTop100New) {
    init();
  }

  return {
    defaultCountry,
    init,
    getChartData,
    availableCountries,
    getChartWeeks,
    getMostRecentChartWeek,
    availableCharts,
  };
});
