declare global {
  interface Window {
    dataLayer: any[];
  }
}

import { DarkModeSetting } from "@/models/frontend-only/DarkMode";
import { StorageSerializers } from "@vueuse/core";

export const useDarkModeStore = defineStore("dark-mode", () => {
  const darkMode = ref<DarkModeSetting>("system");

  // now this variable is used to store for nonsigned in users and for people who are signed in but have not yet upgraded to new cloud based preferences
  const storedDarkMode = useLocalStorage<DarkModeSetting | "">(
    "ss-ui-theme",
    "",
  );

  const profile = useProfileStore();
  const systemDarkMode = usePreferredDark();
  const api = useApiStore();
  let initialized = false;

  const isDarkModeEnabled = computed(
    () =>
      darkMode.value === "dark" ||
      (darkMode.value === "system" && systemDarkMode.value),
  );
  setTheme();

  async function init() {
    await profile.profilePromise;

    if (profile.profile?.signedIn) {
      if (storedDarkMode.value) {
        darkMode.value = storedDarkMode.value;
        saveDarkModeSettingToCloud();
        storedDarkMode.value = "";
      } else {
        if (["dark", "light", "system"].includes(profile.profile.uitheme)) {
          darkMode.value = profile.profile.uitheme as
            | "dark"
            | "light"
            | "system";
        } else {
          darkMode.value = "system";
        }
      }
    } else {
      darkMode.value = storedDarkMode.value || "system";
    }

    setTheme();
    initialized = true;
    watch(() => [darkMode.value, systemDarkMode.value], setTheme);

    watch(darkMode, () => {
      if (initialized) {
        saveDarkModeSettingToCloud();
        if (profile.profile && !profile.profile.signedIn) {
          storedDarkMode.value = darkMode.value;
        }
      }
    });
  }

  function saveDarkModeSettingToCloud() {
    if (profile.profile?.signedIn) {
      api.post("/UpdatePreferredUITheme", {
        uitheme: darkMode.value,
      });
    }
  }

  function setTheme() {
    const html = document.querySelector("html") as HTMLElement;
    const theme = isDarkModeEnabled.value ? "dark" : "light";
    if (isDarkModeEnabled.value) {
      import("@/styles/darkmode.css");
      html.classList.add("dark");
      html.setAttribute("data-theme", "ccli-dark");
    } else {
      html.classList.remove("dark");
      html.setAttribute("data-theme", "ccli-light");
    }
    window.dataLayer = window.dataLayer || [];
    (window as any).dataLayer.push({
      event: "toggleDarkMode",
      preferred_color_scheme: theme,
    });
  }

  return { setTheme, init, darkMode, systemDarkMode, isDarkModeEnabled };
});
