<script setup lang="ts">
defineProps<{
  iconBefore?: string;
  iconAfter?: string;
  to?: string;
  href?: string;
  disabled?: boolean;
  noButton?: boolean;
  active?: boolean;
}>();

const emit = defineEmits<{
  (e: "click"): void;
}>();

function emitClick() {
  emit("click");
}
</script>

<template>
  <li>
    <div
      v-if="noButton"
      class="no-button"
      :class="{ disabled: disabled, active: active }"
    >
      <FAIcon
        v-if="iconBefore"
        class="fa-fw"
        :class="iconBefore"
      />
      <span class="text">
        <slot></slot>
      </span>
      <FAIcon
        v-if="iconAfter"
        class="fa-fw"
        :class="iconAfter"
      />
    </div>
    <a
      v-else-if="href"
      :href="href"
      :class="{ disabled: disabled, active: active }"
    >
      <FAIcon
        v-if="iconBefore"
        class="fa-fw"
        :class="iconBefore"
      />
      <span class="text">
        <slot></slot>
      </span>
      <FAIcon
        v-if="iconAfter"
        class="fa-fw"
        :class="iconAfter"
      />
    </a>
    <router-link
      v-else-if="to"
      :to="to"
      :class="{ disabled: disabled, active: active }"
    >
      <FAIcon
        v-if="iconBefore"
        class="fa-fw"
        :class="iconBefore"
      />
      <span class="text">
        <slot></slot>
      </span>
      <FAIcon
        v-if="iconAfter"
        class="fa-fw"
        :class="iconAfter"
      />
    </router-link>
    <button
      v-else
      type="button"
      :class="{ disabled: disabled, active: active }"
      @click="emitClick"
    >
      <FAIcon
        v-if="iconBefore"
        class="fa-fw"
        :class="iconBefore"
      />
      <span class="text">
        <slot></slot>
      </span>
      <FAIcon
        v-if="iconAfter"
        class="fa-fw"
        :class="iconAfter"
      />
    </button>
  </li>
</template>

<style scoped lang="postcss">
a,
button {
  @apply flex items-center gap-2 whitespace-nowrap;
  &:hover,
  &:focus-visible {
    @apply bg-base-100 text-base-content shadow;
    i,
    svg {
      @apply text-primary;
    }
  }
}
.no-button {
  color: unset !important;
  background: unset !important;
  pointer-events: none;
}

.disabled {
  @apply pointer-events-none opacity-40;
}
.active {
  @apply bg-primary text-white;
}
</style>
