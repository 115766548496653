export function asyncWait(lengthMsec = 200) {
  return new Promise<void>((res) => setTimeout(res, lengthMsec));
}

export function loadFont(id: string, url: string) {
  const elId = `fontScript${id}`;
  const el = document.querySelector(`#${elId}`);
  if (el) {
    return;
  }
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  link.id = elId;
  document.head.appendChild(link);
}

export function loadScript(url: string, id: string) {
  const el = document.querySelector(`#${id}`);
  if (el) {
    return Promise.resolve();
  }
  const script = document.createElement("script");
  script.id = id;
  script.setAttribute("src", url);

  return new Promise<void>((res) => {
    script.addEventListener("load", () => {
      res();
    });
    document.body.appendChild(script);
  });
}

export async function waitUntilTrue(fn: () => any) {
  let count = 0;
  while (!fn()) {
    await new Promise<void>((res) => setTimeout(() => res(), 100));
    count++;
    if (count === 50) {
      const debug = useDebug();
      debug.log("still waiting");
      debug.trace("waitUntilTrue");
    }
  }
}

export function escapePipelineInTranslations(data: {
  [libraryCode: string]: { [phraseKey: string]: any };
}) {
  //for (const countryCode of Object.keys(data)) {
  for (const libraryCode of Object.keys(data)) {
    for (const phraseKey of Object.keys(data[libraryCode])) {
      if (data[libraryCode][phraseKey] !== null) {
        if (data[libraryCode][phraseKey].replaceAll) {
          data[libraryCode][phraseKey] = data[libraryCode][
            phraseKey
          ].replaceAll("|", "{'|'}");
        }
      }
    }
  }
  //}
  return data;
}

export function cloneDeep<T>(value: T) {
  return JSON.parse(JSON.stringify(value)) as T;
}

export function sortBy<T>(items: Array<T>, fn: (data: T) => any) {
  const data = cloneDeep(items);
  data.sort((a, b) => {
    const ansA = fn(a);
    const ansB = fn(b);
    if (ansA < ansB) {
      return -1;
    } else if (ansB < ansA) {
      return 1;
    }
    return 0;
  });
  return data as T[];
}

export function shuffle<T>(arr: Array<T>) {
  const a = cloneDeep(arr);
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

let id = 0;

export function uniqueId(prefix?: string) {
  id++;
  const val = `${prefix || "uniq"}-${id}`;
  return val;
}

export function padZeros(val: number) {
  if (val < 10) {
    return `0${val.toString()}`;
  }
  return val.toString();
}

export function convertSecondsToDisplayTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  return `${minutes}:${padZeros(remainingSeconds)}`;
}
