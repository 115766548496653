export function isSafari(chromeIos?: boolean) {
  const test = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (!test) {
    return false;
  }

  if(!chromeIos){
    return test;
  } else {
    // check to see if this is chrome running on ios
    const safarimatches = navigator.userAgent.match(/safari\/(\d+)/i) || [];
    if (safarimatches[1] && parseFloat(safarimatches[1]) > 100) {
      return false;
    }

    return true;
  }
}

export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
}

export function isBot() {
  if (navigator.webdriver) {
    return true;
  }
  const userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.includes("bot") ||
    userAgent.includes("crawler") ||
    userAgent.includes("spider") ||
    userAgent.includes("robot") ||
    userAgent.includes("chrome-lighthouse")
  ) {
    return true;
  }
  return false;
}
