<script setup lang="ts">
const app = useAppStore();
const beta = useBetaStore();

const modeLabel = (() =>
  app.productionMode ? "" : import.meta.env.MODE.toUpperCase())();
</script>

<template>
  <div id="status-block" />
  <div class="app-wrapper">
    <Header />

    <div class="below-header">
      <div class="content">
        <main>
          <RouterView v-slot="{ Component }">
            <KeepAlive
              :max="3"
              :include="['SearchResultsPage', 'Home', 'Themes', 'Personal', 'LiturgyCalendar', 'LiturgyDate', 'ResponsorialPsalms']">
              <component :is="Component" />
            </KeepAlive>
          </RouterView>
        </main>
        <Footer />
      </div>
    </div>
    <DarkenOverlay />
    <SiteNav />
    <div
      v-if="modeLabel"
      class="mode-badge"
    >
      {{ modeLabel }}
    </div>

    <Toasts />
    <BusyOverlay />
    <Modals />
    <div
      v-if="beta.enableAlphaFeatures"
      class="alpha-msg"
    >
      Alpha Features Enabled
    </div>
  </div>
</template>

<style scoped lang="postcss">
.app-wrapper {
  @apply flex min-h-screen flex-col;
  padding-top: var(--header-height);
}
.below-header {
  @apply relative flex h-full flex-1;
}
.content {
  @apply flex flex-1 flex-col;
  max-width: 100%;

  @media screen(lg) {
    margin-left: var(--nav-width);
    max-width: calc(100% - var(--nav-width));
  }
}
main {
  @apply flex-1;
}

#status-block {
  position: fixed;
  top: -100px;
  width: 100%;
  z-index: 10000;
  height: 100px;
  background: white;
}

.mode-badge {
  @apply fixed right-1 top-0  uppercase text-error;
  font-size: 0.6em;
  z-index: 5000;
}

.alpha-msg {
  @apply fixed right-12 top-0 z-[1000] rounded text-xs uppercase text-orange-500;
  font-size: 10px;
}
</style>
