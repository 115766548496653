import { Language } from "@/models/Language";

const defaultLanguage = "en-US";
const locales = import.meta.glob("@/assets/locales/*.json");

async function loadLanguage(langCode: string) {
  switch (langCode) {
    case "af-ZA":
      return await import("@/assets/locales/af.json");
    case "da-DK":
      return await import("@/assets/locales/da.json");
    case "de-DE":
    case "de-CH":
      return await import("@/assets/locales/de.json");
    case "en-AU":
      return await import("@/assets/locales/en-AU.json");
    case "en-GB":
      return await import("@/assets/locales/en-GB.json");
    case "en-IE":
      return await import("@/assets/locales/en-IE.json");
    case "en-US":
      return await import("@/assets/locales/en-US.json");
    case "es-US":
      return await import("@/assets/locales/es.json");
    case "fi-FI":
      return await import("@/assets/locales/fi.json");
    case "fr-FR":
      return await import("@/assets/locales/fr.json");
    case "ko-KR":
      return await import("@/assets/locales/ko.json");
    case "nl-NL":
      return await import("@/assets/locales/nl.json");
    case "nn-NO":
    case "nb-NO":
      return await import("@/assets/locales/no.json");

    case "pt-BR":
      return await import("@/assets/locales/pt.json");

    case "sv-SE":
      return await import("@/assets/locales/sv.json");
    default:
      throw new Error(`Language ${langCode} not found`);
  }
}

export const useTranslationStore = defineStore("translation", () => {
  const initialized = ref(false);
  const api = useApiStore();
  const profileStore = useProfileStore();
  const i18n = useI18n();
  const availableLanguages = ref<Language[]>([]);
  const loadedLanguageCodes = ref<string[]>([]);
  const currentLanguageCode = useLocalStorage<string>("ss-language", "");

  async function getAvailableLanguages() {
    availableLanguages.value = await api.get("/GetAvailableLanguages");
  }

  async function seti18nLanguage(languageCode: string) {
    const lang = languageCode || defaultLanguage;
    i18n.locale.value = lang;
    const html = document.querySelector("html");
    html?.setAttribute("lang", lang);
  }

  async function getLanguage(languageCode: string) {
    if (!languageCode) {
      return;
    }
    if (loadedLanguageCodes.value.includes(languageCode)) {
      return;
    }

    const loadedMessages = (await loadLanguage(languageCode)).default;
    const messages: any = {};
    messages[languageCode] = loadedMessages || {};

    const languageLookUpTable: {
      [namespace: string]: { [key: string]: string };
    } = escapePipelineInTranslations(messages)[languageCode];

    i18n.setLocaleMessage(languageCode, languageLookUpTable);
    loadedLanguageCodes.value.push(languageCode);
  }

  watch(currentLanguageCode, setLanguage);
  async function setLanguage() {
    useDebug().log("setLanguage");
    const languageCode = currentLanguageCode.value || "en-US";

    if (i18n.locale.value === languageCode) {
      seti18nLanguage(languageCode);
      return;
    }
    await getLanguage(languageCode);
    seti18nLanguage(languageCode);

    emitter.emit("languageUpdated");
    //api.post('/SetLanguage', { userId: profile.profile.userId, language: languageCode});
  }

  async function init() {
    if (initialized.value) {
      return;
    }

    await Promise.all([getAvailableLanguages(), getLanguage(defaultLanguage)]);

    const matchedBrowserLanguages: string[] = [];

    navigator.languages.forEach((lang) => {
      if (availableLanguages.value.find((l) => l.code === lang)) {
        matchedBrowserLanguages.push(lang);
      }
    });

    const matchedBrowserLanguage =
      (matchedBrowserLanguages?.length && matchedBrowserLanguages[0]) || null;

    const languageCode =
      profileStore.profile?.language ||
      currentLanguageCode.value ||
      matchedBrowserLanguage ||
      defaultLanguage;

    let refreshProfile = false;
    if (currentLanguageCode.value !== languageCode) {
      refreshProfile = true;
    }
    useDebug().log("language code", languageCode);
    await getLanguage(languageCode);
    currentLanguageCode.value = languageCode;
    await setLanguage();
    initialized.value = true;

    // need to wait for language to be set first before refreshing profile
    if (refreshProfile) {
      profileStore.getProfile();
    }
    watch(currentLanguageCode, () => profileStore.getProfile());
  }

  function formatNumbers(value?: number | string | null) {
    if (value === null) {
      return "";
    }
    if (!value) {
      return value;
    }

    if (typeof value === "number") {
      try {
        return value.toLocaleString(currentLanguageCode.value);
      } catch (err) {
        return value.toLocaleString(defaultLanguage);
      }
    } else {
      const v = parseInt(value);
      try {
        return v.toLocaleString(currentLanguageCode.value);
      } catch (err) {
        return v.toLocaleString(defaultLanguage);
      }
    }
  }

  return {
    init,
    availableLanguages,
    currentLanguageCode,
    formatNumbers,
  };
});
