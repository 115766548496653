export const useMobileStore = defineStore("mobile", () => {
  const mobileWidth = "250px";
  const hideMenuOnBreakpoint = "lg";
  const showMobileNav = ref(false);
  const searchBarFocus = ref(false);
  const debug = useDebug();

  function setSearchBarFocus(focused: boolean) {
    searchBarFocus.value = focused;
  }

  function toggleMobileNav(show?: boolean) {
    debug.log("toggling nav");
    if (typeof show !== "undefined") {
      showMobileNav.value = !!show;
    } else {
      showMobileNav.value = !showMobileNav.value;
    }
  }

  return {
    showMobileNav,
    toggleMobileNav,
    mobileWidth,
    hideMenuOnBreakpoint,
    searchBarFocus,
    setSearchBarFocus,
  };
});
